import React from "react"
import Layout from "../layouts/layout/layout"
import HeaderSmall from "../components/header-small/header-small"
import HeaderImage from "../../static/media/value-check.png"
import FeatureList from "../components/feature-list/feature-list"
import DefaultLayout from "../layouts/default-layout"
import DefaultTextBlock from "../components/default-text-block/default-text-block"
import ContactButton from '../components/contact-button/contact-button'
import { Helmet } from "react-helmet"

const items = [
  { text: "Vanaf €550 + BTW", key: 1, icon: "fas fa-money-bill-wave-alt" },
  // { text: "Eigendommen tot 250m² (€1 + BTW per extra m²)", key: 2, icon: "" },
  {
    text: "Vervoer 50km inbegrepen (€0,30 + BTW per extra km)",
    key: 3,
    icon: "fas fa-car",
  },
]

export default () => (
  <Layout>
    <Helmet>
      <title>Value-check vastgoed aankoopbegeleding</title>
      <meta name="description" content="Value-check = Tech-check + realistische waardebepaling ( incluis voorstudie ) + volledige aankoopprijsbepaling ( renovatiebudget, alle aankoopkosten) …. Zodat u met een gerust hart naar de bank kan gaan."></meta>
      <meta name="keywords" context="fullcheck, full-check, vastgoed, aakoopbegeleiding, huis, pand, immo, immophone, gent, De Pinte"></meta>          
    </Helmet>
    <HeaderSmall title="Value-check" imageUrl={HeaderImage}></HeaderSmall>
    <DefaultLayout>
      <div className="details">
        <DefaultTextBlock
          title="Value-check"
          text={<div>Value-check = Tech-check + realistische waardebepaling (incluis voorstudie) + volledige aankoopprijsbepaling (renovatiebudget, alle aankoopkosten)... Zodat u met een gerust hart naar de bank kan gaan.</div>}
        ></DefaultTextBlock>
        <FeatureList items={items} title="Concreet"></FeatureList>
      </div>
      <ContactButton></ContactButton>
    </DefaultLayout>
  </Layout>
)
